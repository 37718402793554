export const base_url = "https://api.midjourneyfree.ai/api";
export const dalleActive = true;
export const dalle_2 = false;
export const falActive = false;
export const fastspringActive = true;
export const Gold_subscription_id = "P-7F0168311G4445225M4VP3AI";
export const Gold_Yearly_subscription_id = "P-2EM414583K3113725MX6VWKI";
export const Platinum_subscription_id = "P-68U42306U96052057M4VP4CA";
export const Platinum_Yearly_subscription_id = "P-73T350943S8912410MX6VXBA";
    

// Gold 10$ paypal plan id : P-57H50688M4829033WM2PXKJI

// Platinum 15$ paypal plan id : P-6TJ58565SH994544VM2PXQNI