import React from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { base_url } from "../../../Store/constant";
import axios from "axios";
import swal from "sweetalert";
import { credit_count, plan_details, user } from "../../../Reducer/homeReducer";
import moment from "moment";
import coinimage from "../../../Assets/img/coins.svg";

const Subscription = () => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.persistedReducer.home.userData);
  console.log('userData==',userData);
  
  const planDetails = useSelector(
    (state) => state.persistedReducer.home.plan_details
  );
  const creditCount = useSelector(
    (state) => state.persistedReducer.home.credit_count
  );
  const credit_per =
    planDetails?.price_plan_id == 2
      ? (1000 - creditCount) / (1000 / 100)
      : planDetails?.price_plan_id == 3
      ? (3000 - creditCount) / (3000 / 100)
      : (5 - creditCount) / (5 / 100);

  const handleCancel_Subs = (e) => {
    swal({
      title: "Are you sure?",
      text: "Do you really want to cancel your subscription ?",
      icon: "info",
      buttons: ["No","Yes"],
      dangerMode: true,
    }).then(function (isConfirm) {
      if (isConfirm) {
        axios
          .post(base_url + "/user/cancelSubscription", "", {
            headers: { Authorization: `Bearer ${userData?.token}` },
          })
          .then((response) => {
            if (response.status == 200) {
              userdata();
              swal(
                "Your subscription has been successfully canceled!",
                "",
                "success"
              );
            } else {
              swal(response.data.msg, "", "error");
            }
          })
          .catch((error) => {
            console.log(error);
            swal("Please try again later!", "", "error");
          });
      }
    });
  };
  const userdata = () => {
    axios
      .get(base_url + "/user", {
        headers: { Authorization: `Bearer ${userData?.token}` },
      })
      .then((response) => {
        if (response.status == 200) {
          var userdata = {
            token: userData?.token,
            stability_key: userData?.stability_key,
            user: userData?.user,
            subscription: response.data.data.subcription_details,
            is_login: true,
          };
          dispatch(user(userdata));
          dispatch(
            credit_count(
              response.data.data.plan_details?.credit_count == null
                ? 0
                : response.data.data.plan_details?.credit_count
            )
          );
          dispatch(plan_details(response.data.data.plan_details));
        } else {
          swal(response.data.msg, "", "error");
        }
      })
      .catch((error) => {
        swal(error.response.data.msg, "", "info");
      });
  };
  return planDetails != null ? (
    <div className="Subscription">
      <h3 className="title">Subscription</h3>
      <hr />

      <Row className="mt-4 mb-4">
        <Col md={6}>
          <div className="bg-color-F6 dark:bg-color-3A rounded-xl lg:p-6 p-4 w-full xl:w-full details-body 8xl:w-[71.2%]">
            <div className="d-flex justify-between items-center">
              <p className="text-color-14 dark:text-white text-24 font-Figtree font-semibold">
                Current Plan :
                <span className="heading-3"> {planDetails?.plan_name} </span>
                <span className="text-sm text-color-14 dark:text-white">
                  {" "}
                  ({planDetails?.status})
                </span>
              </p>
            </div>
            <p className="mt-2 text-color-14 dark:text-white font-Figtree font-normal text-15"></p>

            <div className="Imagemidjurny">
              <div className="d-flex justify-left items-center mt-3 text-12 font-Figtree text-color-14 dark:text-white font-normal">
                <p className="mr-3"> <img src={coinimage} /> </p>
                <p>
                Credit Counts Left : {creditCount}
                </p>
              </div>
            </div>

            {/* <div className="Imageresolutionmidjurny">
              <p className="text-color-14 dark:text-white text-15 font-medium font-Figtree mt-6">
                Max Image Resolution : 1024x1024
              </p>
            </div> */}
          </div>
        </Col>
        <Col md={6}>
          <div className="bg-color-F6 dark:bg-color-3A lg:p-6 p-4 ltr:!pr-0 rounded-xl upgrade-plan-card 9xl:w-[34.9%] 8xl:w-[42%] w-full d-flex flex-col justify-between rtl:pl-2">
            <div>
              <p className="text-color-14 dark:text-white text-20 lg:pr-6 pr-4 font-Figtree font-semibold any-sub-p">
                Running out of credits too soon?
              </p>
              <p className="mt-3 text-color-14 dark:text-white font-Figtree font-normal text-14 pr-5">
                Upgrade to our more featured plan for more credits and benefits.
              </p>
            </div>
            <div className="d-flex mt-[26px] justify-start gap-5 flex-wrap">
              <Link
                to="/pricing"
                className="font-Figtree magic-bg h-max upgrade-plan w-max rounded-xl text-16 text-white font-semibold py-3 px-[25px] whitespace-nowrap mr-2"
              >
                Renew Plan
              </Link>
              <Link
                to="/pricing"
                className="font-Figtree magic-bg w-max rounded-xl text-16 text-white font-semibold py-3 px-[25px]"
              >
                All Plan
              </Link>
            </div>
          </div>
        </Col>
      </Row>
      {!planDetails?.is_subscription ? 
      <div className="Subscription">
        <h3 className="title">Billing and Payment</h3>
        <hr />

        <Row className="mt-3 ">
          <Col md={9}>
            <div className="bg-color-F6 dark:bg-color-3A rounded-xl p-6 8xl:w-[66.5%] subscription-profile-card">
              
              <div className="mt-2 d-flex flex-wrap items-center 3xl:gap-10 gap-4 justify-start 6xl:w-[500px] 4xl:w-[450px] xl:w-[400px]">
                <div>
                  <p className="font-normal text-13 font-Figtree text-color-14 dark:text-white">
                    Billing Price
                  </p>
                  <p className="font-semibold text-16 font-Figtree text-color-14 dark:text-white pt-1">
                     {planDetails?.final_amount} {planDetails?.currency}
                  </p>
                </div>
                <div>
                  <p className="font-normal text-13 font-Figtree text-color-14 dark:text-white">
                    Plan Type
                  </p>
                  <p className="font-semibold text-16 font-Figtree text-color-14 dark:text-white pt-1">
                    {planDetails?.billing_cycle}
                  </p>
                </div>
               
                <div>
                  <p className="font-normal text-13 font-Figtree text-color-14 dark:text-white">
                   Start Date
                  </p>
                  <p className="font-semibold text-16 font-Figtree text-color-14 dark:text-white pt-1">
                    {moment(planDetails?.activation_date).format('MMMM Do YYYY')}
                  </p>
                </div>
                <div>
                  <p className="font-normal text-13 font-Figtree text-color-14 dark:text-white">
                    End Date
                  </p>
                  <p className="font-semibold text-16 font-Figtree text-color-14 dark:text-white pt-1">
                    {moment(planDetails?.next_billing_date).format('MMMM Do YYYY')}
                  </p>
                </div>
                <div>
                  <p className="font-normal text-13 font-Figtree text-color-14 dark:text-white">
                    Payment Status
                  </p>
                  <p className="font-semibold text-16 font-Figtree text-color-14 dark:text-white pt-1">
                    {planDetails?.payment_status}
                  </p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      <h3 className="title mt-4">Get More with Our Premium Plan!</h3>
      <hr />
      <h2 className="mt-4 mb-2">Subscribe to our premium plan</h2>
      <p className="mb-4">
      for ongoing access or purchase one-time credits to get immediate benefits.
      </p>
      <Link className="mt-4 theme-btn " to="/pricing">
        All Plan
      </Link>
      
    </div> :<div>
        <h3 className="title">Billing and Payment</h3>
        <hr />

        <Row className="mt-3 ">
          <Col md={8}>
            <div className="bg-color-F6 dark:bg-color-3A rounded-xl p-6 8xl:w-[66.5%] subscription-profile-card">
              
              <div className="mt-2 d-flex flex-wrap items-center 3xl:gap-10 gap-4 justify-start 6xl:w-[500px] 4xl:w-[450px] xl:w-[400px]">
                <div>
                  <p className="font-normal text-13 font-Figtree text-color-14 dark:text-white">
                    Billing Price
                  </p>
                  <p className="font-semibold text-16 font-Figtree text-color-14 dark:text-white pt-1">
                     {planDetails?.final_amount} {planDetails?.currency}
                  </p>
                </div>
                <div>
                  <p className="font-normal text-13 font-Figtree text-color-14 dark:text-white">
                    Billing Cycle
                  </p>
                  <p className="font-semibold text-16 font-Figtree text-color-14 dark:text-white pt-1">
                    {planDetails?.billing_cycle}
                  </p>
                </div>
                <div>
                  <p className="font-normal text-13 font-Figtree text-color-14 dark:text-white">
                    Payment Status
                  </p>
                  <p className="font-semibold text-16 font-Figtree text-color-14 dark:text-white pt-1">
                    {planDetails?.payment_status}
                  </p>
                </div>
                <div>
                  <p className="font-normal text-13 font-Figtree text-color-14 dark:text-white">
                    Next Billing Date
                  </p>
                  <p className="font-semibold text-16 font-Figtree text-color-14 dark:text-white pt-1">
                    {moment(planDetails?.next_billing_date).format('MMMM Do YYYY')}
                  </p>
                </div>
              </div>
            </div>
          </Col>
        </Row>

       {planDetails?.is_subscription == 1 ? 
       <div className="mt-10">
          <h3 className="title">{planDetails?.status == "Active"  ? "Unsubscribe" : "Plan Cancelled"}</h3>
          <hr />
          <div className="pt-6 pb-24">
           { planDetails?.status == "Active" ? 
           (<p className="dark:text-white font-normal font-Figtree text-15">
              Cancelling your subscription will not cause you to lose your
              current existing credits and plan benefits. But you can subscribe
              again anytime and get to keep all your saved documents and
              history.
            </p>)
            :
            (<p>Your Subscription has been cancelled Succefully</p>)
            }
            <div className="btns-group mt-4">
              {planDetails?.status == "Active" &&
              <button
                onClick={handleCancel_Subs}
                title=""
                className="theme-btn"
                disabled={planDetails?.status == "Active" ?false:true}
              >
           Cancel Subscription
              </button>
              
               }
            </div>
          </div>
        </div>:
      <div className="mt-10">
      <h3 className="title">Credit Plans</h3>
      <hr />
      <div className="pt-6 pb-24">
       <p className="dark:text-white font-normal font-Figtree text-15">
          Cancelling your subscription will not cause you to lose your
          current existing credits and plan benefits. But you can subscribe
          again anytime and get to keep all your saved documents and
          history.
        </p>
      </div>
    </div>
      }
      </div>}
    </div>
  ) : (
    <div className="Subscription">
      <h3 className="title">Subscription</h3>
      <hr />
      <h2 className="mt-4 mb-2">You do not have any subscription</h2>
      <p className="mb-4">
        Subscribe to our more featured plan for more credits and benefits.
      </p>
      <Link className="mt-4 theme-btn " to="/pricing">
        All Plan
      </Link>
    </div>
  );
};

export default Subscription;
